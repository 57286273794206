import React from "react";
import Layout from '../admin/Layout';
import acessIcon from "../../assets/images/access-icon.svg";
import { useTranslation } from "react-i18next";
import { decode } from "../../assets/js/common";
import * as Constants from "../../config/constants";
import PatientLayout from "./PatientLayout";

export default function AccessDenied() {

	const { t } = useTranslation();
	const user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
	const userRole = (user && user?.role ? decode(user?.role): null);

	const bodyHtml = (
		<div style={{background: "#f1f1f1"}}>
			<div style={{height: "calc(100vh - 70px)"}}>
				<div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", height: "100%"}}>
					<img src={acessIcon} alt="Access Denied" className="icon" style={{marginBottom: "15px"}} />
					<h1 style={{color: "rgb(33, 37, 41)", letterSpacing: "0", marginBottom: "10px",fontWeight: "600"}}>{t('access-denied')}</h1>
					<p style={{marginBottom: "0", textTransform: "capitalize",fontSize: "16px"}}>{t('access-denied-msg')}</p>
				</div>
			</div>
		</div>
	);

	return (
		(userRole !== Constants.ROLE_PATIENT ? (
			<Layout pageName={t('access-denied')}>
				{bodyHtml}
			</Layout>
		): (
			<PatientLayout pageName={t('access-denied')}>
				{bodyHtml}
			</PatientLayout>
		))
	);
}