import React from "react";
import PatientHeader from "../../../component/patient-header";

export default function PatientMainHeader({settingInfo, paymentInfo}) {

    return (
        <header>
            <main className="twt-theme theme-colour vertical-header">
                <PatientHeader settingInfo={settingInfo} paymentInfo={paymentInfo}/>
            </main>
        </header>
    );
}