import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import * as Constants from "../config/constants";
import { useTranslation } from "react-i18next";
import {getformattedNumber, handleActiveClass} from '../assets/js/common.js';
import logo from "../assets/images/spinalogy-logo.png";
import {
    Navbar,
    Dropdown
} from "react-bootstrap";
import { ProfilePicComponent } from "./ProfilePicComponent.js";

const PatientHeader = ({ settingInfo, paymentInfo }) => {

    useEffect(() => {
        handleActiveClass();
        if (window.innerWidth > 1199) {
            const wrapper = document.querySelector('#wrapper');
            const navSlideOpen = document.querySelector('#backend-content');
            if (wrapper && wrapper.classList) {
                wrapper.classList.add("toggled");
                navSlideOpen.classList.add("nav-slide-open");
                navSlideOpen.classList.add("toggled");
                
            }
        }
    }, []);


    const toggleNav = () => {
        document.querySelector('#wrapper').classList.toggle("toggled");
        document.querySelector('.backend-content').classList.toggle("toggled");
    };
    document.addEventListener("click", function(e) {
        const wrapper = document.querySelector('#wrapper');
        if (wrapper && window.innerWidth < 1199 && !e.target.closest("#wrapper")) {
            document.querySelector('#wrapper').classList.remove("toggled");
        }
    });

    useEffect(() => {
        const currentPath = window.location.pathname;
        const links = document.querySelectorAll('.patientheader-link');
    
        links.forEach(link => {
          if (link.getAttribute('href') === currentPath) {
            link.classList.add('active');
          } else {
            link.classList.remove('active');
          }
        });
      }, []);

    const { t } = useTranslation();
    const user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
    return (
        <div id="wrapper" className="wrapper patientheader-wrapper">
            <Navbar className="navbar-dark" expand="">
                <button onClick={toggleNav} className="navbar-toggler ripple me-auto d-xl-none d-flex" type="button">
                    <span className="navbar-toggler-icon" />
                </button>
                <Link className="navbar-brand p-lg-0 d-xl-flex d-none" to={Constants.PATIENT_DASHBOARD_URL}>
                    {settingInfo?.v_website_logo && settingInfo.v_website_logo ? (
                        <img src={settingInfo?.v_website_logo ? settingInfo.v_website_logo : logo} alt={t('praheal')} className="img-fluid big-image nav-logo" />
                    ) : (
                        null
                    )}
                </Link>
                <div className="patientheader-link-mdiv">
                    <div className="sidebar" id="sidebar">
                        <ul className="patientheader-link-ul">
                            <li className="patientheader-link-li">
                                <Link to={Constants.PATIENT_DASHBOARD_URL} className="patientheader-link" title="Home">Home</Link>
                            </li>
                            <li className="patientheader-link-li">
                                <Link to={Constants.PATIENT_APPOINTMENTS_URL} className="patientheader-link" title="Appointments">Appointments</Link>
                            </li>
                            <li className="patientheader-link-li">
                                <Link to={Constants.PATIENT_PLANS_URL} className="patientheader-link" title="Plans">Plans</Link>
                            </li>
                            <li className="patientheader-link-li">
                                <Link to={Constants.PATIENT_KRITIS_URL} className="patientheader-link" title="Kritis">Kritis</Link>
                            </li>
                        </ul>
                        <div className="header-payment-status-div ms-xl-auto">
                            {(paymentInfo?.payment_status && paymentInfo?.payment_status === Constants.PAYMENT_STATUS_DUE) && (
                                <>
                                    <h4 className="header-payment-status">Payment Status:</h4>
                                    <h5 className="header-payment-amount due">{paymentInfo?.payment_status} ({t('indian-currency')} {getformattedNumber(paymentInfo?.outstanding_amount)})</h5>
                                    {/* for green color add class "advance"  */}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <Dropdown className="dropdown admin-dropdown me-lg-0">
                        <Dropdown.Toggle variant="" className="admin-dropdown" id="admin-dropdown">
                            <ProfilePicComponent user={user} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu-right">
                            <Link className="dropdown-item" to={Constants.PATIENT_PROFILE_URL} title={t('view-profile')}><i className="fi fi-rr-user-pen password"></i> {t('view-profile')}</Link>
                            <hr className="admin-dropdown-hr" />
                            <Link to={Constants.LOGOUT_URL} className="dropdown-item logout-btn text-dark text-decoration-none font-15 d-flex align-items-center" title={t('logout')}><i className="fi fi-rr-sign-out-alt password"></i> {t('logout')}</Link>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Navbar>
            <div className="sidebar d-none" id="sidebar">
                <ul className="sidebar-nav">
                  
                </ul>
                {/* {settingInfo?.v_powered_by && (
                    <div className="fixed-footer border-top p-2">
                        <p className="text-center small mb-0">© {moment().year()} <a href={settingInfo?.v_powered_by_link ? settingInfo.v_powered_by_link : void(0) } target="_blank" rel="noopener noreferrer">{settingInfo.v_powered_by}</a></p>
                    </div>
                )} */}
            </div>
        </div>
    );
}
export default PatientHeader