import { useTranslation } from "react-i18next";
import * as Constants from "../config/constants";
import { decode, ucWord, validateLinkByUserAndRole } from "../assets/js/common";
import { Link } from "react-router-dom";

export function ProfilePicComponent({ user, altText = "User", type = "", data = {} }) {

    const { t } = useTranslation();

    switch (type) {
        case "appointment-calender":
            return (
                <div className="patientcard-name-mdiv">
                    <div className="patientcard-name-sort">
                        {data?.appointmentData?.patient_profile_pic ? 
                            <div className="profile-card">
                                <img src={data?.appointmentData?.patient_profile_pic} alt={data?.appointmentData?.patient_name || t('patient')} />
                            </div>
                        : <p>{(data?.appointmentData && data?.appointmentData?.patient_name) ? data?.appointmentData?.patient_name.substring(0, 1).toUpperCase() : ''}</p>}
                    </div>
                    {
                        validateLinkByUserAndRole(data?.appointmentData?.status, data?.permissions) && data?.appointmentData?.id
                        ?
                        <Link to={Constants.ADD_CONSULTATION_URL + '/' + data?.appointmentData?.id} className="patientcard-full-name">{data?.appointmentData?.patient_name}</Link>
                        :
                        <p className="patientcard-full-name">{data?.appointmentData?.patient_name}</p>
                    }
                </div>
            )
        case "internal-messaging":
            return (
                <div className="welcome-div">
                    {user?.user_pic ? <div className="user-img-div">
                        <img src={user?.user_pic} alt={user?.name || altText} />
                    </div>
                    : <div className="icon-div">{(user && user.name) ? user.name.substring(0, 1).toUpperCase() : ''}</div>}
                    <div className="text-div">
                        <h5 className="welcome-text">{t('welcome')}</h5>
                        <h6 className="name">{user?.name || altText}</h6>
                    </div>
                </div>
            )
        default:
            const userRole = (user?.user_master_role ? decode(user.user_master_role) : (user?.role ? decode(user.role) : null));
            return (
                <span className="img-user align-middle rounded-circle d-blcok">
                    {user?.user_pic ? <div className="user-img-div">
                        <img src={user?.user_pic} alt={user?.name || altText} />
                    </div>
                    : <div className="user-img-div user-srot-name-div">
                        <p className="user-srot-name">{(user && user.name) ? user.name.substring(0, 1).toUpperCase() : ''}</p>
                    </div>}
                    <div className="user-content-div">
                        <p id="username" className="d-inline-block align-middle user-name">{(user && user.name) ? user.name : ''}</p>
                        {(userRole && userRole !== Constants.ROLE_PATIENT) && <p id="username" className="d-inline-block align-middle user-designation">{ucWord(userRole)}</p>}
                    </div>
                </span>
            )
    }
}