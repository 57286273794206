import React, { useEffect, useState } from "react";
import "../../assets/css/flaticon-uicons/css/all/all.css";
import Header from './includes/patientHeader';
import Footer from './includes/footer';
import { seo } from '../../seo';
import { useTranslation } from "react-i18next";
import * as Constants from '../../config/constants';
import { apiCall , dynamicMetaTags, getPatientDue, manageNetworkError, valueCheck } from "../../assets/js/common";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import icon from "../../assets/images/icon.png";
import { useNavigate } from "react-router";

export default function PatientLayout({ children , pageName , renderReq = false, paymentInfo }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [settingInfo , setSettingInfo] = useState([]);
    const [paymentDetail, setPaymentDetail] = useState(paymentInfo);
    
    const getSettingInfo = async () => {
      setPaymentDetail(await getPatientDue());
      const getSettingData = await apiCall('get', Constants.SETTING_URL, null, false);
      if (getSettingData?.status !== false) {
        if (getSettingData?.data?.data) {
            setSettingInfo(getSettingData?.data?.data?.recordInfo);
        }
      } else {
          manageNetworkError(getSettingData);
      }
    }

    const user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
  
    useEffect(() => {
        if(!user || !user.token) {
          navigate(Constants.PATIENT_LOGIN_URL);
        }
    } , [navigate, user]);

    useEffect(() => {
        getSettingInfo();
      // eslint-disable-next-line
    } , [renderReq]);

    useEffect(() => {
      seo({
        title: typeof settingInfo?.v_site_title !== "undefined" &&
        settingInfo.v_site_title !== null &&
        settingInfo.v_site_title !== ""
        ? pageName + ' | ' + settingInfo.v_site_title
        : "Loading...",
      });
    }, [t, pageName, settingInfo]);

    useEffect(() => {
      const removeTagList = dynamicMetaTags.join(',');
      document.querySelectorAll(removeTagList).forEach(e => {
        if(!e.getAttribute('data-rh')) {
          e.remove();
        }
      });
    } , []);
    
    return (
      <>
        <HelmetProvider>
          <Helmet>
            <meta name='title' content={(pageName !== null && pageName !== "" ? pageName : 'Home' ) + ' | ' + valueCheck(settingInfo?.v_site_title)} />
            <meta name='description' content={ valueCheck(settingInfo?.v_site_description) } />
            <meta name='keywords' content={ valueCheck(settingInfo?.v_site_keywords) } />
            <meta name='author' content={ valueCheck(settingInfo?.v_meta_author)  } />
            <meta name='copyright' content={ valueCheck(settingInfo?.v_site_title) } />
            <meta name='publisher' content={ valueCheck(settingInfo?.v_meta_author)  } />
            
            <meta property='og:title' content={ valueCheck(settingInfo?.v_site_title) } />
            <meta property='og:description' content={valueCheck(settingInfo?.v_site_description) } />
            <meta property="og:url" content={Constants.SITE_URL} />
            <meta property='og:site_name' content={ valueCheck(settingInfo?.v_site_title) } />
            <meta property='og:image' content={settingInfo?.v_website_og_icon ? (settingInfo.v_website_og_icon) : icon} />

            <meta property="twitter:url" content={Constants.SITE_URL} />
            <meta property='twitter:title' content={valueCheck(settingInfo?.v_site_title)} />
            <meta property='twitter:description' content={ valueCheck(settingInfo?.v_site_description) } />
            <meta property='twitter:image' content={settingInfo?.v_website_logo ? (settingInfo.v_website_logo) : icon} />
            
            <link rel="icon" href={settingInfo?.v_website_fav_icon ? (settingInfo.v_website_fav_icon) : ''} />
            <link rel="apple-touch-icon" href={settingInfo?.v_website_fav_icon ? (settingInfo.v_website_fav_icon) : ''} />  
            
            <meta name="theme-color" content={Constants.ADMIN_THEME_COLOR}></meta>
          
          </Helmet>
          <Header settingInfo={settingInfo} paymentInfo={paymentDetail}/>
            <main className="backend-content" id="backend-content">{children}</main> 
          <Footer settingInfo={settingInfo}/>
        </HelmetProvider> 
      </>
    )
}