import React, { useState, useRef, useEffect } from "react";
import { Col, Form, Button,Tooltip,OverlayTrigger } from "react-bootstrap";
import { Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from "react-i18next";

const DropdownField = ( props = {} ) => {
	const ref = useRef();
	const [currentWidth, setCurrentWidth] = useState();
	const { t } = useTranslation();

	// const modalOpen = document.querySelector('body').hasAttribute('data-rr-ui-modal-open');
	// console.log(modalOpen)
	const updateWidth = () => {
		if(ref && ref?.current) {
			const element = ref.current?.getElement();
			setCurrentWidth(element.clientWidth);
		}
    };


	useEffect(() => {
        updateWidth();
    }, []);

	const fieldName = props?.name;
	const formErrors = props?.errors;
	const additionalRules = { setValueAs: value => value ? value?.trim() : "" };

	const handleKeyPress = (e) => {
		if(e?.originalEvent?.nativeEvent?.keyCode === 13) {
			ref?.current?.focus();
		}
	}

	return (
        <Col lg={props?.column} xs={props?.columnXs} xxl={props?.xxl} size={props?.size} md={props?.md} className={props?.colClass}>
        	<Form.Group className="form-group">
        		{props?.label && <Form.Label>{props.label}{ props?.required ? <span className="text-danger">*</span> : '' }
						{props?.tooltipInfo && (
							<OverlayTrigger
								placement={props?.placement || 'bottom'}
								overlay={<Tooltip className="bg-white-tooltip" style={{position:"fixed"}} id={`tooltip-${props?.placement || 'bottom'}`}>{props?.tooltipInfo}</Tooltip>}
							>
								<span className="info-tooltip">
									<i className="fi fi-rr-info" />
								</span>
							</OverlayTrigger>
						)}
						</Form.Label>}
        		<Controller
	        	    name={fieldName}
	        	    control={props?.control}
					defaultValue={props?.value || ''}
		            rules={ props?.rules ?  { ...props?.rules, ...additionalRules } : additionalRules }
	        	    render={({ field, fieldState }) => (
						<>
							{props?.quickAdd === true ?
								<div className="input-group">
									<div className="flex-fill">
										<Dropdown
											ref={ref}
											name={fieldName}
											value={field.value}
											title={
												props?.options?.find((option) => option.value === field.value)?.label || ''
											}
											placeholder={props?.placeholder || t('select')}
											options={props?.options}
											focusInputRef={field.ref}
											onChange={(e) => {
												field.onChange(e.value);
												if(props?.onFieldChange) {
													props?.onFieldChange(e);
												}
												handleKeyPress(e);
											}}
											className={classNames({ 'p-invalid': fieldState.error }, ( props?.classList || '' ))}
											pt={{
												panel: {
													style: {
													...(currentWidth ? { width: currentWidth } : {}),
													},
												},
											}}
											onShow={updateWidth}
											onFocus={updateWidth}
											filter={props?.isFilterEnabled}
											filterInputAutoFocus
											autoOptionFocus={props?.isFilterEnabled}
											resetFilterOnHide={props?.isFilterEnabled}
											disabled={props?.disabled}
											readOnly={props?.readOnly}
											showClear={field?.value ? (!props?.required || props?.showClear) : false}
										/>
									</div>
									<div className="input-group-append">
										<Button variant="theme" className="quick-add-btn" onClick={props?.onQuickBtnClick} size="sm" title={t('add')}><i className="fi fi-rr-plus icon"></i></Button>
									</div>
								</div>
								:
								<Dropdown
									ref={ref}
									name={fieldName}
									value={field.value}
									title={
										props?.options?.find((option) => option.value === field.value)?.label || ''
									}
									placeholder={props?.placeholder || t('select')}
									options={props?.options}
									focusInputRef={field.ref}
									onChange={(e) => {
										field.onChange(e.value);
										if(props?.onFieldChange) {
											props?.onFieldChange(e);
										}
										handleKeyPress(e);
									}}
									className={classNames({ 'p-invalid': fieldState.error }, ( props?.classList || '' ))}
									pt={{
										panel: {
											style: {
											...(currentWidth ? { width: currentWidth } : {}),
											},
										},
									}}
									onShow={updateWidth}
									onFocus={updateWidth}
									filter={props?.isFilterEnabled}
									filterInputAutoFocus
									autoFocus={props?.autoFocus}
									appendTo={props?.appentTo}
									autoOptionFocus={props?.isFilterEnabled}
									resetFilterOnHide={props?.isFilterEnabled}
									disabled={props?.disabled}
									showClear={field?.value ? (!props?.required || props?.showClear) : false}
								/>
							}
						</>
	        	    )}
	        	/>
				{ ( formErrors && formErrors[fieldName]?.message ) && ( 
					<Form.Label className="invalid-input" htmlFor={fieldName} dangerouslySetInnerHTML={{__html: formErrors[fieldName]?.message}}></Form.Label> 
        	    )}
        	</Form.Group>
        </Col>
    )
}
export default DropdownField